@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.Ingredient{
    margin-top: 40px;
    padding: 10px;
    padding-bottom: 40px;
  }
  @media (max-width: 625px) {
    .Ingredient{
      margin-top: 50px;
      padding: 10px;
      padding-bottom: 40px;
    }
  }
.addingredenthead {
  color: #2e2e2e;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
}
.incredenthdivchoose {
  background-color: #ffff;
  padding: 5px 20px;
  border-radius: 12px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.catagris {
  font-weight: 600;
  color: #32343494;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
}

label {
  font-weight: 500;
  color: #2c2c2e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.Schedule-menu {
  padding: 10px;
  border-radius: 12px;
  background-color: #ffff;
  margin-top: 15px;
}
.Schedule-menutext {
  font-weight: 700;
  color: #2e2e2e;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}
.Schedule-dishname {
  font-weight: 500;
  color: #111827;
  font-size: 15;
  font-family: "Poppins", sans-serif;
  line-height: 20.96px;
  align-items: center;
  text-align: center;
  margin-top: 5px;
}

.ingred {
  margin: 0px 0px 5px 0px !important;
  font-family: "Poppins", sans-serif;
  color: #111827;
  font-weight: 500;
  font-size: 16px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group-input {
  padding: 6px;
  width: 100%;
  font-size: 16px;
  border: 2px solid #d1d1d1;
  border-radius: 8px;
}
.form-group-input:focus {
  outline: none;
  border: 2px solid #d1d1d1;
  border-radius: 8px;
}

.addbutton, .saveincredite {
  padding: 5px 20px;
  margin: 0 7px;
  width: 220px;
  font-family: "Poppins", sans-serif;
  border-radius: 7px;
  cursor: pointer;
}

.addbutton {
  font-size: 16px;
  color: #e18722;
  border: 1.5px solid #e18722;
  background-color: #fff1c0;
}

.saveincredite {
  font-size: 16px;
  background-color: #e18722;
  color: #E3FFC0;
  border: 1.5px solid #ffa500;
}

@media (max-width: 1200px) {
  .addbutton, .saveincredite {
    width: 200px;
    font-size: 14px;
    padding: 7px 15px;
  }
}

@media (max-width: 768px) {
  .addbutton, .saveincredite {
    width: 200px;
    font-size: 12px;
    padding: 6px 10px;
  }
}

@media (max-width: 480px) {
  .addbutton, .saveincredite {
    width: 200px;
    font-size: 11px;
    padding: 6px 10px;
  }
}

.textareaddtext{
  background-color:#C1EE400F;
}

.remove-btn{
  font-size: 16px;
  cursor: pointer;
  color:rgb(255, 76, 76);
}
@media (max-width: 600px) {
  .remove-btn {
    font-size: 12px;
  }
}


@media (min-width: 601px) and (max-width: 1024px) {
  .remove-btn {
    font-size: 14px;
  }
}


@media (min-width: 1025px) {
  .remove-btn {
    font-size: 16px;
  }
}


.form-select {
border: 2px solid #d1d1d1 !important;
}

.selecttes {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 2px solid #d1d1d1 !important;
  padding: 10px !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.New-Create-Ingredientbtn{
  padding: 6px 10px;
  margin: 10px 7px;
  width: 220px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  background-color: #e18722;
  color:#ffffff;
  border: 1.5px solid #ffa500;
  border-radius: 7px;
  cursor: pointer;
}
.saves-Ingredientbtn{
  padding: 7px 10px;
  margin-top: 20px ;
  width: 220px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  background-color: #e18722;
  color:#ffffff;
  border: 1.5px solid #ffa500;
  border-radius: 7px;
  cursor: pointer;
}
.ingredients-view{
  font-family: "Poppins", sans-serif;
  color: #ffa500;
  text-align: center;
}
.Ingredients-div{
  padding-left: 20px;
  padding-right: 20px;
}
.deleteincredent{
  font-size: 14px;
  color: #d1d1d1;
  cursor: pointer;
}
.deleteincredent:hover{
  color: #ff0000;
}
.bulleticonfixedmenu {
  flex-shrink: 0; /* Prevent SVG from shrinking */
}

.ingredient-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px; /* Default for larger screens */
  display: inline-block;
}

@media (max-width: 1200px) {
  .ingredient-text {
    max-width: 120px; /* Adjust for medium-sized screens */
  }
}

@media (max-width: 768px) {
  .ingredient-text {
    max-width: 100px; /* Adjust for tablets and small screens */
  }
}

@media (max-width: 576px) {
  .ingredient-text {
    max-width: 150px; /* Adjust for mobile screens */
  }
}
