@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.orders{
    margin-top: 40px;
    padding: 20px;
    padding-bottom: 40px;
  }
  @media (max-width: 625px) {
    .orders{
      margin-top: 50px;
      padding: 10px;
      padding-bottom: 40px;
    }
  }
  .orders-texth{
    color:#2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size:19px;
    margin: 7px 0px 0px 0px !important;
  }

  @media (max-width: 625px) {
    .orders-texth{
      color:#2E2E2E;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size:15px;
      margin: 11px 0px 0px 0px !important;
    }
  }
  
  .Addorders{
    background-color: #ffff;
    padding: 7px;
    font-family: "Poppins", sans-serif;
    border-radius:7px;
    margin: 0px 0px 0px 0px !important;
    font-size: 14px;
    width: 150px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; 
}
.Assignorderbtn{
  background-color: #E18722;
  padding: 7px;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  border-radius:7px;
  margin: 0px 0px 0px 0px !important;
  font-size: 14px;
  width: 90px;
  border: none;
}
.editbtn{
  background-color:#04AA6D;
  padding: 7px;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  border-radius:7px;
  margin: 0px 0px 0px 0px !important;
  font-size: 14px;
  width: 90px;
  border: none;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content {
  background: white;
  width: 400px;
  padding:35px;
  border-radius: 10px;
}
@media (max-width: 625px) {
  .popup-content {
    padding:20px;
   
  }
}

.sheulde-order{
  color:#0b0b0b;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:20px;
  
  margin: 0px 0px 0px 0px !important;
}
.Asseventsave{
  background-color: #E18722;
  padding: 8px;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  border-radius:7px;
  margin: 20px 0px 0px 0px !important;
  font-size: 14px;
  width: 100%;
  border: none;
}
.sheulde-orderform{
  color:#0b0b0b;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:19px; 
}
.Assign-content{
  background: white;
  width: 450px;
  padding: 18px;
  border-radius: 12px;
  overflow-x: hidden;
}
.Assigneventrofile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.maindiv{
  height: 385px;
  overflow-y: auto;
  overflow-x: hidden;
}
.managenametext{
  color:#2E2E2E;
  font-family: "Poppins", sans-serif;
  font-size:16px;
  margin: 10px 0px 0px 12px !important;
}
.managenamesmalltext{
  color:#2E2E2E;
  font-family: "Poppins", sans-serif;
  font-size:13px;
  margin: 0px 0px 0px 12px !important;
}
.Assigneventorder{
  background-color: #E18722;
  padding: 8px;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  border-radius:30px;
  margin: 5px 0px 0px 0px !important;
  font-size: 14px;
  width: 30%;
  border: none;
}
.close-icons{
  color: rgb(48, 48, 48);
  align-items: end;
  text-align: end;
  cursor: pointer;
}
.close-icons:hover{
  color: rgb(138, 138, 138);

}

.viewmenubtns{
  background-color:#E18722;
  color:#ffffff;
  border: none;
  width: 100%;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.viewmenulink{
  text-decoration: none;
  color: #ffffff;
}
.scrolldivmenus{
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Apply ellipsis */
.ellipsistd {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Tooltip styling */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgb(250, 250, 250);
  color: #000000;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Adjust positioning */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
