@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ChatMessage {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.Message {
    align-self: flex-end;
    background: #F8B602;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 5px;
    max-width: 70%;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.Response {
    align-self: flex-start;
    background: #EAEAEA;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    padding: 5px;
    border-radius: 10px;
    max-width: 70%;
    color: #333;
}

.Timestamp {
    font-size: 0.8em;
    color: #000000;
    margin-top: 1px;
    align-self: flex-end;
    font-family: "Poppins", sans-serif;

}
.Timestampus {
    font-size: 0.8em;
    color: #000000;
    margin-top: 1px;
    align-self: flex-end;
    font-family: "Poppins", sans-serif;
}

.Error {
    color: red;
    text-align: center;
}
.chathistorychoosediv{
    background-color: #ffff;
    padding: 13px;
    margin-top: 20px;
    border-radius: 10px;
}
.adminuser{
    margin-bottom: 0px !important;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #0000008A;
    cursor: pointer;
}
.adminuser:hover{
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #FF9723;
    text-decoration:underline;
}
.iconhistory{
    margin-right: 10px;
}
.textuse{
    text-decoration: none;
}
.historyview{
  padding: 0px 8px;
  color: #ffff;
  background-color: #FF9723 !important;
  font-family: "Poppins", sans-serif;
  border-radius:7px;
  margin: 0px 0px 0px 0px !important;
  font-size: 14px;
  max-width: 100px;
  width: 100%;
  border: none;
  }

  .userbtn {
    background: #eea426;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    font-size: 17px;
    color:#ffffff;
    position: relative;
    width: 100%;
    max-width:150px;
    padding: 5px;
    border:solid 2px#eea426 ;
  }

.BotLabel{
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    color: #000;
    margin-right: 10px;
}
.UserLabel{
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    color: #000;
    font-weight: 500;
    margin-right: 5px;
}