.menusopensubcatagrymain {
  background-color: #ffff;
  border-radius: 7px;
  padding: 7px 10px;
  max-width: 90%;
  width: 400px;
}

.menusopensubcatagry {
  background-color: #ffff;
  padding: 4px 14px;
  font-family: "Poppins", sans-serif;
  border-radius: 7px;
  font-size: 14px;
  width: 100%;
  border: none;
}

.popup-overlaysubcataory {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.subcatary-savebtn {
  background-color: #e18722;
  color: #fff;
  border: none;
  font-family: "Poppins", sans-serif;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menusopensubcatagrymain {
    width: 90%;
    padding: 10px;
  }

  .menusopensubcatagry {
    padding: 10px 15px;
    font-size: 12px;
  }

  .subcatary-savebtn {
    padding: 5px 15px;
  }
}

@media (max-width: 480px) {
  .menusopensubcatagrymain {
    width: 100%;
    margin: 0 10px;
  }

  .menusopensubcatagry {
    padding: 10px;
    font-size: 12px;
  }

  .subcatary-savebtn {
    padding: 5px 10px;
    font-size: 12px;
  }
}
.removicpnd {
  cursor: pointer;
  color: #353535;
  padding: 0.2rem 4px;
}

.removicpnd:hover {
  padding: 0.1rem 4px;
  border-radius: 2px;
  border: solid rgb(255, 157, 10) 2px;
}