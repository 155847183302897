@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.addish {
  margin-top: 40px;
  padding: 20px;
  padding-bottom: 40px;
}
@media (max-width: 625px) {
  .addish {
    margin-top: 50px;
    padding: 10px;
    padding-bottom: 40px;
  }
}
.adddishead {
  color: #2e2e2e;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
}
.dishdivchoose {
  background-color: #ffff;
  padding: 5px 20px;
  border-radius: 12px;
  margin-top: 0px;
}

.catagris {
  font-weight: 600;
  color: #32343494;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
}

label {
  font-weight: 500;
  color: #2c2c2e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.Schedule-dishname {
  font-weight: 500;
  color: #111827;
  font-size: 15;
  font-family: "Poppins", sans-serif;
  line-height: 20.96px;
  align-items: center;
  text-align: center;
  margin-top: 5px;
}

.ingred {
  margin: 0px 0px 5px 0px !important;
  font-family: "Poppins", sans-serif;
  color: #111827;
  font-weight: 500;
  font-size: 15px !important;
}

.form-group {
  margin-bottom: 10px;
}

.form-group-input {
  padding: 6px;
  width: 100%;
  font-size: 16px;
  border: 2px solid #f7f7f7;
  border-radius: 8px;
}
.form-group-input:focus {
  outline: none;
  border: 2px solid #f1f1f1;
  border-radius: 8px;
}
.button-groupadd {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.textareaddtext{
  background-color:#eece400f;
}

.remove-btn{
font-size: 15px;
  cursor: pointer;
  color:rgb(255, 76, 76);
}

.form-select {
border: 2px solid #d1d1d1 !important;
}

.selecttes {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 2px solid #d1d1d1 !important;
  padding: 10px !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}




.generalacardination{
  /* border: 1px solid rgb(255, 255, 255); */
  padding: 4px  10px;
  box-shadow: rgba(159, 158, 158, 0.2) 0px 2px 8px 0px;
  border-radius: 20px; 
  color: #170F49;
  margin: 0px 0px 0px 0px !important;
  font-weight: 650px;
  background-color:rgb(255, 255, 255) !important;
  border-bottom: solid 2px #e18722;
}

.headtexthelp{
  font-size: 15px;
  font-weight: 600;
  color: rgb(2, 1, 7);
  font-family: "Poppins", sans-serif;
  margin: 0px 0px 0px 0px !important;
}
.chevronbutton{
  margin: 4px 0px 0px 0px !important;
}
@media screen and (max-width: 958px) {
  .chevronbutton{
    text-align: end;
  }
}

.chevron {
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 50%; 
  padding: 6px 9px;
  color:#ffffff;
  background-color:#e18722;
  box-shadow: rgba(159, 158, 158, 0.2) 0px 2px 8px 0px;
 
}

.chevron.rotate { 
  transform: rotate(180deg);
  border: 2px solid rgb(249, 249, 249);
  border-radius: 50%; 
  padding: 5px;
  color: #ffffff;
  background-color: #e18722;
  box-shadow:none;

}
.accadtext-body {
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  background-color: rgb(255, 255, 255);
}
.accadtext{
  margin: 0px 0px 0px 0px !important;
}



.trashicon{
  font-size: 18px;
  cursor: pointer;
}



.fixedmenueditbtn {
  margin: 0 5px;
  font-size: 18px;
  color: #161616;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  text-align: end;
  align-items: end;
  padding: 4px;
}



.bulleticonfixedmenu{
margin-right: 12px;
}
.fixedmenu-textdata{
  font-family: "Poppins", sans-serif;
  color: #000000; 
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.checbox{
  margin-right: 5px;
}

.ellipsisadd {
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;   
  width: 150px;             
}
@media screen and (max-width: 768px) {
  .ellipsisadd {
    width: 250px;
  }
}


.fixedaddbtn {
  margin:5px 6px;
  font-size: 14px;
  color: #e18722;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 4px;
}
.fixedcatagorybtn {
  margin-top:6px;
  font-size: 9px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 2px;
  background-color: #cfcfcf;
  border-radius: 50%;
}
.fixedcatagoryeditbtn{
  margin-top:5px;
  font-size: 12px;
  color: #cfcfcf;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 2px 4px;
}
.fixedcatagorydeletebtn {
  margin-top: 5px;
  font-size: 12px;
  color: #cfcfcf;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 2px 4px;
}

.catagroryhover:hover {
  background-color: #ededed;
  border-radius: 7px;
}

.catagroryhover:hover .fixedcatagorydeletebtn {
  color: red;
}
.catagroryhover:hover .fixedcatagoryeditbtn {
  color: #e18722;
}
.catagroryhover:hover .fixedcatagorybtn{
  background-color:#e18722;
  color: #ffffff;
}