@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.manager{
  margin-top: 40px;
  padding: 20px;
  padding-bottom: 40px;
}
@media (max-width: 625px) {
  .manager{
    margin-top: 60px;
    padding: 10px;
    padding-bottom: 40px;
  }
}
.manager-texth{
  color:#2E2E2E;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:19px;
  margin: 0px 0px 0px 0px !important;
}

.Addmanager{
    background-color: #ffff;
    padding: 7px;
    font-family: "Poppins", sans-serif;
    border-radius:7px;
    margin: 0px 0px 0px 0px !important;
    font-size: 14px;
    width: 100px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; 
}
.iconelips{
    margin: 7px 15px 0PX 0px !important;
    color: #ffff;
    cursor: pointer;
  
}
.manager-backgroundimg{
    background-image: url('../../assets/MaskGroup.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 18px;
  }
 

  .manager-cardbody{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0px 10px 10px 10px !important;
  }
  .managerprofile{
    display: flex;
    align-items: center;
  }
  .managerprofile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  .managerheadtext{
    margin: 0px 0px 0px 0px !important;
    font-family: "Poppins", sans-serif;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: 650;
  }
  .manager-textsmall{
    margin: 0px 0px 0px 0px !important;
    font-family: "Poppins", sans-serif;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 400;
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(44, 43, 43, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; 
  }
  .manager-content {
    background: white;
    width: 350px;
    padding: 25px 45px 25px 45px !important;
    border-radius: 20px;
  }
  .Team-management{
    color:#030303;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size:18px;
    text-align: center;
    margin: 0px 0px 0px 0px !important;
  }
  .Team-managementbtn{
    background-color: #E18722;
  padding: 8px;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  border-radius:7px;
  margin: 20px 0px 0px 0px !important;
  font-size: 14px;
  width: 100%;
  border: none;
  }

  .imageUploadContainer {
    position: relative;
    margin-bottom: 20px;
  }
  
  .imageLabel {
    display: block;
    cursor: pointer;
  }
  
  .imageUploadInput {
    display: none;
  }
  
  .placeholderteam{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-image: url('../../assets/Elips.png');
    background-size: cover;
    display: flex;
    align-items: center;
    border: 2px solid #fc7e00f6;
    justify-content: center;  
  }
  
  .uploadIcon {
    width: 35px;
    color: #888;
  }
  
  .image {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 3px solid #fc7e00f6;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .managerscardoption {
    position: absolute;
    right: 0px;
    top: 25px;
    width: 100px;
    text-align: center;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    padding: 5px 0px 5px 0px ;
    border-radius: 10px;
  }
  .actionedit{
    margin:0px 0px 0px 0px !important;
    border-bottom: 1px solid rgb(139, 139, 139) ;
    font-size: 14px;
    color: #404040;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  }
  .actiondelete {
    margin:0px 0px 0px 0px !important;
    font-size: 14px;
    color: #FF6464;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  }
  .manageredit-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(44, 43, 43, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999 ; 
  }
  .manageredit-content {
    background: white;
    width: 350px;
    padding: 25px 45px 25px 45px !important;
    border-radius: 20px;
  }
 