@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.menusopenpopup {
    background: white;
    width: 100%;
    max-width: 400px;
    padding: 15px !important;
    border-radius: 11px;
    font-family: "Poppins", sans-serif;
  }
  
  .menuheadx {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif;
  }
  
  .dishdivchoose {
    width: 100%;
    font-family: "Poppins", sans-serif;
  }
  
  .form-group-inputsadd {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    outline: none; /* Removes the default outline */
  }
  
  .form-group-inputsadd:focus {
    border:solid 1px rgb(160, 160, 160); /* Removes border on focus */
    outline: none; /* Ensures no outline appears */
  }
  
  
  .button-groupadd {
    display: flex;
    justify-content: end;
    gap: 15px;
    margin-top: 10px;
  }
  
  .insert-name-savebtn {
    background-color: #e18722;
    color: #fff;
    border: none;
    font-family: "Poppins", sans-serif;
    width: 40%;
    padding: 7px 25px;
    border-radius: 5px;
    cursor: pointer;
  }
  
 .close-savebtn{  
    width: 40%;
    background-color: #ffffff;
    color:#e18722;
    border: 1px solid #e18722;
    padding: 7px 15px;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
}
  
  .overlayadd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(44, 43, 43, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; 
  }
  
  .menusopencatagry {
    background: white;
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
  }
  
  .menuheadxcatagry{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 1.1rem;
    color: #333;
    padding: 10px;
    background-color: #e18722;
    margin-bottom: 15px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .dishdivchoose {
    width: 100%;
    font-family: "Poppins", sans-serif;
  }
  
  .form-group-inputcatary {
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
  }
  .form-group-inputcatary:focus {
    border-color: #b3b3b3; /* Change this color to whatever focus color you prefer */
    outline: none; /* Removes the default browser outline */
}
  .button-groucatary {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .catary-savebtn {
    background-color: #e18722;
    color: #fff;
    border: none;
    font-family: "Poppins", sans-serif;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
  }
  
 .closecatary-savebtn{  
    background-color: #ffffff;
    color:#e18722;
    border: 1px solid #e18722;
    padding: 5px 15px;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
}

.menusAddcatagry {
  background: white;
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  padding: 15px;
  font-family: "Poppins", sans-serif;
}
.menusAddhead{
  background: white;
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}
.Addheght{  
  max-height: 30vh;
  overflow-y: auto;
}

.catarymenu-savebtn {
  background-color: #e18722;
  color: #fff;
  border: none;
  font-family: "Poppins", sans-serif;
  padding: 5px 25px;
  border-radius: 5px;
  cursor: pointer;
}

.closecatary-btn{  
 
  background-color: #ffffff;
  color:#e18722;
  border: 1px solid #e18722;
  padding: 5px 15px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.childmenucatagry{
  background: white;
  width: 100%;
  max-width: 450px;
  border-radius: 10px;
  padding: 8px 15px;
  font-family: "Poppins", sans-serif;
}
.childmenucatagryelipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px; 
  display: inline-block;
  vertical-align: middle;
}
.closeiconchildcatagory{
  color: #323232;
  font-size: 16px;
  cursor: pointer;
}
.iconfoods{
  margin-right: 10px;
}
.fixedmenudeletebtn {
  margin: 0 5px;
  font-size: 15px;
  color: #161616;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  text-align: end;
  align-items: end;
  color: red;
  padding: 4px;
}
.no-data-message{
  color: #333;
}