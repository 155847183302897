@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.menus {
  margin-top: 40px;
  padding: 10px;
  padding-bottom: 30px;
}
@media (max-width: 625px) {
  .menus {
    margin-top: 50px;
    padding: 10px;
    padding-bottom: 30px;
  }
}

  .itemdd{
    display: flex;
    justify-content: center;
    align-items: center;
  }
.menuheadx{
    color:#2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size:20px;
   
}
.Adddishbtn{
    background-color: #E18722;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    padding: 6px 25px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
   
}
.addlistdiv{
    display: flex;
    justify-content: end;
    align-items: end;
    margin-top: 5px;
}

.beavergecatagry {
  background-color: #ffffff;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: solid 2px rgb(221, 149, 76);
}
.subdiv {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 8px;
  margin-top: 5px;
  margin-right: 8px;
  margin-left: 8px;
  max-height: 0; /* Collapsed height */
  opacity: 0; /* Hidden initially */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Smooth transition */
}

.subdiv.show {
  max-height: 4000px; 
  opacity: 1; 
}


.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.catagaryname {
  font-weight:500;
  font-family: "Poppins", sans-serif;
  padding: 5px 10px;
  color: #000000;
  margin: 4px 0px 0px 0px !important;
}

.ellipsis {
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;   
  width: 200px;             
}
.sub-catagry {
  font-family: "Poppins", sans-serif;
  padding-left: 15px;
  padding-right: 15px;
}

.sub-catagry-name {
  font-size: 16px;
  font-weight: 600;
  padding: 0.3rem 10px; 
  margin-bottom: 20px ;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon-group .faEdit, .icon-group .faTrash {
  font-size: 0.8rem;
  margin-left: 1rem;
  color: #cfcfcf;
  cursor: pointer;
}
.faEdit:focus {
  outline: none; 
  border: none; 
}


.sub-catagry-name:hover {
  background-color: #e18722; 
  color: #fff; 
  border-radius: 3px;
}

.sub-catagry-name:hover .faEdit {
  color: #ffffff; 
}

.sub-catagry-name:hover .faTrash {
  color: rgb(255, 255, 255); 
}


.menu-button {
  width: 100%;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #f6f6f6;
  background-color: #ffefe669;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;  border-radius: 4px;
}

.menu-button:hover {
  background-color: rgb(230, 127, 25);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed; 
  top: 0;
  left: 0; 
  font-weight: 1000;
}

.insert-name-savebtn {
  padding: 7px 15px;
  margin: 0px;
  width: 200px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  background-color: #e18722;
  color:#ffffff;
  border: 1.5px solid #ffa500;
  border-radius: 7px;
  cursor: pointer;
}

.chevronmenusicon {
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 50%; 
  padding: 8px;
  color:#ffffff;
  background-color:#e18722;
  box-shadow: rgba(159, 158, 158, 0.2) 0px 2px 8px 0px;
 cursor: pointer;
 transition: transform 0.3s ease;
}

.chevronmenusicon.rotate {
  transform: rotate(180deg);
}

.menunamecreate{
  background: white;
  width:auto  ;
  padding: 20px 20px !important;
  border-radius: 20px;
}
.menunamecreate-poplay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; 
}
.savedish{
  padding: 7px 15px;
  margin: 0 7px;
  width: 120px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  background-color: #e18722;
  color:#ffffff;
  border: 1.5px solid #ffa500;
  border-radius: 7px;
  cursor: pointer;
}
.Schedule-menu {
  padding:10px;
  border-radius: 12px;
  background-color: #ffff;
  margin-top: 0px;
}
.Schedule-menutext {
  font-weight: 700;
  color: #2e2e2e;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}
.position-relative {
  position: relative;
}

.delete-icon {
  top: -8px;
  right: 4px;
  padding: 0px 2px;
  font-size: 0.7rem; 
  color: red; 
  cursor: pointer;
  cursor: pointer;
  border-radius: 4px;
  border: solid rgb(99, 99, 99) 1px;
}
.pluseiconsubadd{
  background-color: #e18722;
  border-radius:5px;
  color: #fffefe;
  padding: 4px 5px;
  cursor: pointer;
  font-size: 15px;
}
.pluseiconsubadd:hover {
  background-color: #ffff;
  color: #e18722; 
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
  .popup-overlaycataory {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.popup-content {
  background: white;
  padding: 10px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
}
.button-grouaddcatary {
  display: flex;
  justify-content:end;
  gap: 15px;
 padding-top: 0px !important;
 padding-left: 12px !important;
 padding-right: 12px !important;
 padding-bottom: 8px !important
}
.form-cataryadd{
  padding: 8px;
  width: 100%;
 border-radius: 5px;
 border: 2px solid rgb(179, 179, 179);
}
.custom-fade-tooltip {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: #262626aa !important;
}

.custom-fade-tooltip.show {
  opacity: 1 !important;
}
.chevron-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

/* Popup size */
.custom-swal-popup {
  width: 350px;
  padding: 10px;
  padding: 0 0 1em;
}

/* Title size */
.custom-swal-title {
  font-size: 20px;
  padding: .5em 0.8em 0;
}

/* Content size */
.custom-swal-content {
  font-size: 16px;
}

.custom-swal-icon {
  font-size: 9px; /* Adjust icon size */
  color: #ff2525 !important;
  border-color: rgb(255, 38, 38) !important;
  margin: 1.5em auto .6em; 
}

.custom-confirm-button {
  background-color: #e18722 !important;
  color: white !important;
  padding: 6px 20px;
  border-radius: 5px;
  font-size: 16px;
  border:2px solid #e18722 !important;
  outline: none !important;
}

.custom-cancel-button {
  background-color: #ffffff !important; 
  color: #E18722 !important;
  padding: 6px 20px !important;
  border-radius: 5px;
  font-size: 16px;
  border: 2px solid #e18722 !important;
}
.faEditcategory,
.faTrashcategory {
  font-size: 14px;
  color: rgb(189, 189, 189);
  transition: color 0.3s;
}

.faEditcategory:hover{
  color: #e18722;
}
.faTrashcategory:hover {
  color: rgba(255, 0, 0, 0.763); 
}
.faEditcategory:focus{
  border: none !important;
  outline: none !important;
}
.faTrashcategory:focus{
  border: none !important;
  outline: none !important;
}
