.modal-title {
    color: #e18722 ;
    width: 100%;
    font-weight: 800;
}

.modal-header {
    box-shadow: 0px 2px 7px -3px #e28722;
    border: none;
}

.Edit-name {
    color: rgb(65, 65, 65);
    font-weight: 700;
}

.modal-body {
    color: black;
}

.menu-list-cls {
  overflow: auto;
  max-height: calc(100vh - 350px);
  height: calc(100vh - 350px);
  min-height: calc(100vh - 350px);
}

.custom-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blurs everything behind it */
    z-index: 1040; /* Make sure it's behind the modal but above content */
  }
  
  .modal-xl {
    width: 100%;
    max-width: 100%;
    margin: 0px;
  }

  .list-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 132px;
    overflow: auto;
  }

  .list-of-items {
    background-image: linear-gradient(#e28722, #fcb66d);
    padding: 8px 20px;
    border-radius: 22px;
    font-size: 15px;
    color: white;
    font-weight: 700;
    margin-right: 20px !important;
    margin: 12px 0px;
    box-shadow: 0px 2px 8px -2px #474747;
  }