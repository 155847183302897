@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

.login-body {
  background-image: url("../../assets/logoback.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0.6 rgb(0, 0, 0);
}

@media only screen and (max-width: 768px) {
  .login-body {
    background-size: auto;
    overflow: hidden;
  }
}

.login-box {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.logo {
  width: 100%;
  margin-bottom: 20px;
}

.reglable {
  font-family: "Poppins", sans-serif;
  text-align: start;
  align-items: start;
  color: #e8e8e8;
}

.reg-form {
  width: 100%;
  max-width: 100%;
  width: 100%;
  margin-bottom: 2px;
  padding: 10px;
  border: 2px solid #ffffff;
  color: #ffffff;
  border-radius: 8px;
  background-color: rgba(27, 27, 27, 0.511);
  font-family: "Poppins", sans-serif;
}

.reg-form:focus {
  outline: 0;
  box-shadow: none !important;
}

.login-button {
  background-color: #e98718;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.forgot-password-link {
  display: block;
  margin-top: 15px;
  color: #ffff;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.eyepassword {
  color:#ffff;
  font-size: 16px;
  cursor: pointer;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.custom-inputs::placeholder {
  color: #ffff;
  font-family: "Poppins", sans-serif;
}