@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.dashboard {
  margin-top: 40px;
  padding: 10px;
  padding-bottom: 10px;
}
@media (max-width: 625px) {
  .dashboard {
    margin-top: 60px;
    padding: 10px;
    padding-bottom: 10px;
  }
}
body{
  background-color: rgba(241, 241, 241, 0.874)
}

.dashstart {
  color: #2e2e2e;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
}
@media only screen and (max-width: 600px) {
  .dashstart {
    font-size: 24px; /* Adjust the font size for mobile */
  }
}
.teammangers{
    color:#2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size:20px;
    margin-top: 10px;  
}
.card-backgroundimg{
  background-image: url('../../assets/cards.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 18px;
  
}


.upcoming-events{
  color:#2E2E2E;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size:20px;
  margin-top: 20px;
}
.eventsdiv{
  background-color: #ffff;
  border-radius: 10px;
  padding: 5px;
  margin-top: 15px;
}
.table{
  padding: 0px !important;
 margin: 0px 0px 0px 0px !important;
  border-bottom:solid 1px #ffff !important;
}
.tabletext{
  font-family: "Poppins", sans-serif;
}

@media (max-width: 480px) {
  .dashboard {
    margin-top: 50px;
    padding: 5px;
    padding-bottom: 30px;
  }
  .dashstart {
    font-size: 25px;
  }
  .teammangers {
    font-size: 18px;
  }

  .upcoming-events{
    font-family: "Poppins", sans-serif;
    font-size:18px;
    margin-top: 10px;
  }
}

.managementimg{
  width: 45px;
  height: 40px;
  
}
.managementimg1{
  width: 45px;
  height: 45px;
}
.textmanagemnt1{
  margin: 12px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color:#1DC9A0;
  font-size: 14px;
  font-weight: 400;
}
.textmanagemnt2{
  margin: 12px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color:#FF5E5E;
  font-size: 14px;
  font-weight: 400;
}
.textmanagemnt3{
  margin: 12px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color:#F8B602;
  font-size: 14px;
  font-weight: 400;
}
.textmanagemnt4{
  margin: 12px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color:#534FEB;
  font-size: 14px;
  font-weight: 400;
}
.cardcounts{
  margin: 0px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color: #1DC9A0;
  font-size: 24px;
  font-weight: 600;
}
.cardcounts2{
  margin: 0px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color:#FF5E5E;
  font-size: 24px;
  font-weight: 600;
}
.cardcounts3{
  margin: 0px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color: #F8B602;
  font-size: 24px;
  font-weight: 600;
}
.cardcounts4{
  margin: 0px 0px 0px 0px !important;
  font-family: "Poppins", sans-serif;
  color:#534FEB;
  font-size: 24px;
  font-weight: 600;
}
.eventsbg{
  background-color:#ffff;
  padding: 10px;
  border-radius: 7px;
}
.arrorrleft-icon{
  font-size: 16px;
  font-weight: 1100;
  margin-top: 5px;
  cursor: pointer;
  margin-right: 5PX;
  margin-left: 6PX;
}
.arrorright-icon{
  font-size: 16px;
  font-weight: 1000;
  margin-top: 5px;
  cursor: pointer;
  margin-left: 6PX;
}
.tabborder{
  border-bottom: 2px solid #bcbcbc77; 
}