@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.vendor{
  margin-top: 40px;
  padding: 20px;
  padding-bottom: 40px;
}
@media (max-width: 625px) {
  .vendor{
    margin-top: 60px;
    padding: 10px;
    padding-bottom: 40px;
  }
}
.vendor-textsmall{
    color:#2E2E2E;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size:19px;
    margin: 0px 0px 0px 0px !important;
}

.Addvendors{
    background-color: #ffff;
    padding: 7px;
    font-family: "Poppins", sans-serif;
    border-radius:7px;
    margin: 0px 0px 0px 0px !important;
    font-size: 14px;
    width: 100px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; 
}
.iconelips{
    margin: 7px 15px 0PX 0px !important;
    color: #ffff;
  
}
.card-bodyvendors {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0px 10px 10px 10px !important;
  }

  .vendors-backgroundimg{
    background-image: url('../../assets/MaskGroup.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 18px;
  }
  .vendorsprofile{
    display: flex;
    align-items: center;
  }
  .vendorsprofile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  .vendorheadtext{
    margin: 0px 0px 0px 0px !important;
    font-family: "Poppins", sans-serif;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 650;
  }
  .vendors-textsmall{
    margin: 0px 0px 0px 0px !important;
    font-family: "Poppins", sans-serif;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
  }

  .vendorscardoption {
    position: absolute;
    right: 0px;
    top: 25px;
    width: 100px;
    text-align: center;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    padding: 5px 0px 5px 0px ;
    border-radius: 10px;
  }
  .actionvendordelete{
    margin:0px 0px 0px 0px !important;
    font-size: 14px;
    color: #FF6464;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  
  }
  .actionvendoredit{
   margin:0px 0px 0px 0px !important;
    border-bottom: 1px solid rgb(139, 139, 139) ;
    font-size: 14px;
    color: #404040;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  }
 
  @media (max-width: 1200px) {
    .vendorelipses {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px; 
      display: inline-block;
      vertical-align: middle;
    }
}